import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, from, mergeMap } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authenticationService.isAuthenticated$.pipe(
      first(),
      mergeMap((isAuthenticated) => {
        if (isAuthenticated) {
          return from(this.authenticationService.idToken()).pipe(
            mergeMap((token) => {
              const authReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token?.toString()}`),
              });
              return next.handle(authReq);
            }),
          );
        }
        return next.handle(req);
      }),
    );
  }
}
